import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const ContactSuccess = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Thanks"/>
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contact</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button={false}/>
        </div>
      </div>
      <hr/>
      <div className="row m-5">
        <div className="col-12 p-sm-2 p-md-5  text-center alert-success">
          <h3>Thanks we will be in touch.</h3>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactSuccess;
